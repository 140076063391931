import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseApi } from '../../../../core/classes/base-api';
import { productDetailApi } from '../../../../backend/product-detail.api';
import { ProductDetailCreateRequestDto } from '../../../models/api/product-detail-create-request.dto';
import { ProductDetailCreateResponseDto } from '../../../models/api/product-detail-create-response.dto';
import { ProductDetailModifyRequestDto } from '../../../models/api/product-detail-modify-request.dto';
import { ProductDisplayResponse } from '../../../models/api/product-display-response.model';

@Injectable({
    providedIn: 'root',
})
export class ProductDetailApiService extends BaseApi {
    private _baseUrl = this.getBaseUrl();

    constructor(
        private readonly _http: HttpClient,
    ) {
        super();
    }

    postProductDetailCreate(productDetailCreateRequest: ProductDetailCreateRequestDto): Observable<ProductDetailCreateResponseDto> {
        return this._http.post<ProductDetailCreateResponseDto>(`${this._baseUrl}${productDetailApi.postProductDetailCreate}`, productDetailCreateRequest);
    }

    patchProductDetailModify(productDetailModifyRequest: ProductDetailModifyRequestDto): Observable<void> {
        return this._http.patch<void>(`${this._baseUrl}${productDetailApi.patchProductDetailModify}`, productDetailModifyRequest);
    }

    getProductDetailFindProductDetailUuid(productDetailUuid: string): Observable<ProductDisplayResponse> {
        const url = productDetailApi.getProductDetailFindProductDetailUuid.replace('{productDetailUuid}', productDetailUuid);
        return this._http.get<ProductDisplayResponse>(`${this._baseUrl}${url}`);
    }
}
